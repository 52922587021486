import React from 'react'
import phoneIcon from '../images/icon-phone.svg'
import emailIcon from '../images/icon-email.svg'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'
import youtube from '../images/youtube.svg'

export default function Footer() {
  return (
    <div>
        <footer class="">
      <div class="container mx-auto pt-6 md:pt-12 px-5 pb-4">

        <div
          class="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0"
        >

          <div class="mt-4 space-y-2">
            <div class="flex items-center space-x-3 md:-mt-10">
              <div class="w-6">
                <img src={phoneIcon} alt="" class="scale-10" />
              </div>
              <div>+1-737-235-7964</div>
            </div>
            <div class="flex items-center space-x-3">
              <div class="w-6">
                <img src={emailIcon} alt="" class="scale-10" />
              </div>
              <div>info@odeweddingmusic.com</div>
            </div>
          </div>

          <div
            class="flex flex-col space-y-10 text-xl md:text-base md:space-x-20 md:space-y-0 md:flex-row"
          >
          </div>

          <div class="flex justify-center pb-10 space-x-3">
            <div>
              <a href="https://www.facebook.com/odeweddingmusic/" target="_blank">
                <img
                  src={facebook}
                  alt=""
                  class="p-2 bg-dustyRose rounded-full ficon"
                />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/channel/UCAkmfkU5a2BKIyVCPg6DSMQ" target="_blank">
                <img
                  src={youtube}
                  alt=""
                  class="p-2 bg-dustyRose rounded-full ficon"
                />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/odeweddingmusic/" target="_blank">
                <img
                  src={instagram}
                  alt=""
                  class="p-2 bg-dustyRose rounded-full ficon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}
