import React from 'react'

export default function SongbookModal ( { showSongBookModal, setShowSongBookModal }) {
  return (
    <>
      {showSongBookModal ? (
        <>
          <div
            className="justify-center items-center lg:flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-[20rem] md:max-w-[44rem] lg:max-w-4xl xl:max-w-5xl ">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-ivory outline-none focus:outline-none">
                {/*header*/}
                <div className="absolute left-1 top-0">
                  <button
                    className="text-darkGray font-bold uppercase text-xl"
                    type="button"
                    onClick={() => setShowSongBookModal(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-3">
                  <h3 className="text-2xl text-center font-semibold text-darkGray">
                    Ceremony Songbook
                  </h3>
                </div> 
                {/*body*/}
                <div className="relative px-5 py-2 flex-auto">


        {/* recordings container */}
        <div class="flex flex-col md:flex-row">
        
        {/* row 1 */}
        <div class="mt-3 md:mt-1 w-[15rem] mx-auto">
          <h1 class="font-semibold text-lg pb-1">Country</h1>
            <a href="https://www.youtube.com/watch?v=kl5sSN7iDd8&ab_channel=CodyJohnson" class="text-sm hover:text-dustyRose" target="_blank">With You I Am</a>
            <br/>
            <a href="https://www.youtube.com/watch?v=7qaHdHpSjX8&ab_channel=BrettYoungVEVO" target="_blank" class="text-sm hover:text-dustyRose">In Case You Didn't Know</a>
            <br/>
            <a href="https://www.youtube.com/watch?v=A3svABDnmio" target="_blank" class="text-sm hover:text-dustyRose">Starting Over</a>
            <br/>
            <a href="https://www.youtube.com/watch?v=nADTbWQof7Y&ab_channel=JoshTurnerVEVO" target="_blank" class="text-sm hover:text-dustyRose">Your Man</a>
            <br/>
            <a href="https://www.youtube.com/watch?v=nCf2PoTuh4Q&ab_channel=BlakeShelton" target="_blank" class="text-sm hover:text-dustyRose">God Gave Me You </a>
            <br/>
<a href="https://www.youtube.com/watch?v=MPoN-FNB2V8&ab_channel=ChrisStapletonVEVO" target="_blank" class="text-sm hover:text-dustyRose">Millionaire</a>
<br/>
<a href="https://www.youtube.com/watch?v=Y2E71oe0aSM" target="_blank" class="text-sm hover:text-dustyRose">10,000 Hours</a>
<br/>
<a href="https://www.youtube.com/watch?v=BM5Xa1FvNQQ&ab_channel=KaceyMusgravesVEVO" target="_blank" class="text-sm hover:text-dustyRose">Butterflies</a>
<br/>
 <a href="https://www.youtube.com/watch?v=2AJ4i4S_fP8" target="_blank" class="text-sm hover:text-dustyRose">It's Your Love </a>
    </div>

        {/* row 2 */}
    <div class="mt-3 md:mt-1 pb-1 w-[15rem] mx-auto">
      <h1 class="font-semibold text-lg pb-1">Pop</h1>
        <a href="https://www.youtube.com/watch?v=QVeubNxaBsg" target="_blank" class="text-sm hover:text-dustyRose">To Make You Feel My Love</a>
        <br/>
<a href="https://www.youtube.com/watch?v=gvPMVKUI9go" target="_blank" class="text-sm hover:text-dustyRose">The Bones</a>
<br/>
<a href="https://www.youtube.com/watch?v=rtOvBOTyX00" target="_blank" class="text-sm hover:text-dustyRose">A Thousand Years</a>
<br/>
<a href="https://www.youtube.com/watch?v=2Vv-BfVoq4g&ab_channel=EdSheeran" target="_blank" class="text-sm hover:text-dustyRose">Perfect</a>
<br/>
<a href="https://www.youtube.com/watch?v=450p7goxZqg&ab_channel=johnlegendVEVO" target="_blank" class="text-sm hover:text-dustyRose">All of Me</a>
<br/>
<a href="https://www.youtube.com/watch?v=lp-EO5I60KA&ab_channel=EdSheeran" target="_blank" class="text-sm hover:text-dustyRose">Thinking Out Loud</a>
<br/>
 <a href="https://www.youtube.com/watch?v=LjhCEhWiKXk&ab_channel=BrunoMars" target="_blank" class="text-sm hover:text-dustyRose">Just the Way You Are</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=bo_efYhYU2A&ab_channel=LadyGagaVEVO" target="_blank" class="text-sm hover:text-dustyRose">Shallow</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=erywPdFfORE" target="_blank" class="text-sm hover:text-dustyRose">Falling in Love at a Coffee Shop</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=yzavsRSpuXc&ab_channel=raylamontagneVEVO" target="_blank" class="text-sm hover:text-dustyRose">Let it Be Me</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=ac3HkriqdGQ&ab_channel=LifehouseVEVO" target="_blank" class="text-sm hover:text-dustyRose">You And Me</a>
    </div>

            {/* row 3 */}
            <div class="mt-3 md:mt-1 pb-1 w-[15rem] mx-auto">
              <h1 class="font-semibold text-lg pb-1">Classic</h1>
                <a href="" target="_blank" class="text-sm hover:text-dustyRose">Ave Maria</a>
                <br/>
<a href="https://www.youtube.com/watch?v=uAnnj1hHL5o&ab_channel=KiralArtists" target="_blank" class="text-sm hover:text-dustyRose">Bridal March</a>
<br/>
<a href="https://www.youtube.com/watch?v=90K1fIA-AVs&ab_channel=PhilipDeYoung" target="_blank" class="text-sm hover:text-dustyRose">A Heart Full of Love</a>
<br/>
<a href="https://www.youtube.com/watch?v=Ptk_1Dc2iPY&list=RDPtk_1Dc2iPY&start_radio=1&ab_channel=BrooklynDuo" target="_blank" class="text-sm hover:text-dustyRose">Canon in D</a>
<br/>
<a href="https://www.youtube.com/watch?v=1qJU8G7gR_g&ab_channel=EttaJames-Topic" target="_blank" class="text-sm hover:text-dustyRose">At Last</a>
<br/>
<a href="https://www.youtube.com/watch?v=rBrd_3VMC3c&ab_channel=LouisArmstrongVEVO" target="_blank" class="text-sm hover:text-dustyRose">What a Wonderful World</a>
<br/>
<a href="https://www.youtube.com/watch?v=vGJTaP6anOU&ab_channel=ElvisPresleyVEVO" target="_blank" class="text-sm hover:text-dustyRose">Can't Help Falling In Love</a>
<br/>
<a href="https://www.youtube.com/watch?v=eH3giaIzONA&ab_channel=whitneyhoustonVEVO" target="_blank" class="text-sm hover:text-dustyRose">I Wanna Dance with Somebody </a>
    </div>

               {/* row 4 */}
               <div class="mt-3 md:mt-1 pb-1 w-[15rem] mx-auto">
                 <h1 class="font-semibold text-lg pb-1">Christian</h1>
                  <a href="" target="_blank" class="text-sm hover:text-dustyRose">Amazing Grace</a>
                  <br/>
 <a href="https://www.youtube.com/watch?v=ax_NMWLEb6U&ab_channel=LSwe1" target="_blank" class="text-sm hover:text-dustyRose">Come Thou Fount</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=0k1WhFtVp0o&ab_channel=borisimmo1" target="_blank" class="text-sm hover:text-dustyRose">Great is Thy Faithfulness</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=aJxrX42WcjQ&ab_channel=WarnerRecordsVault" target="_blank" class="text-sm hover:text-dustyRose">You Raise Me Up</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=8GV9pQo4wZo&ab_channel=HillsongWorship" target="_blank" class="text-sm hover:text-dustyRose">What a Beautiful Name</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=m4_LMlLa9KI&ab_channel=ChrisRice-Topic" target="_blank" class="text-sm hover:text-dustyRose">How Great Thou Art</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=BH8dblPtwVM&ab_channel=TheVillageChurch-Topic" target="_blank" class="text-sm hover:text-dustyRose">Be Thou My Vision</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=KUBw0B6RdBQ&ab_channel=FreeForWorship" target="_blank" class="text-sm hover:text-dustyRose">Oh Love that Will Not Let Me Go</a>
 <br/>
 <a href="https://www.youtube.com/watch?v=hlsIwmtb0U8&ab_channel=crossingctr" target="_blank" class="text-sm hover:text-dustyRose">Divine Romance</a>
    </div>

    </div>  
    </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
};
