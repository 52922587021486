import React from 'react'

export default function Hero() {
  return (
    <div>
      <section id="hero" class="pt-6 ">
      <div class="container mx-auto px-6 text-center pt-[1.3rem] md:pt-[4rem] pb-6">
        <p
          class="tracking-wide text-darkGray max-w-md md:max-w-4xl mx-auto mb-4 md:mb-8 font-bold leading-normal text-5xl"
        >
          Don't Settle For a Spotify Playlist Wedding Ceremony...
        </p>
        <p class="text-darkGray text-center max-w-md mx-auto mb-6 text-lg md:max-w-4xl md:text-xl
        tracking-wide leading-loose sm:leading-loose md:leading-loose">
          You deserve a wedding as unique as your story. Let the Ode Wedding Music Group perform the 
          music for your ceremony. We'll make it
          unique, beautiful, and most importantly - a reflection of who YOU are.
        </p>
        </div>
      </section>
    </div>
  )
}




  