import React from 'react'
import YoutubeEmbed from '../YoutubeEmbed'

export default function Videos({ setShowFormModal }) {
  return (
    <div id="videos" class="pt-10">
      {/*Video Container*/}
      <div class="container mx-auto px-4 md:pt-6 pb-6">
      {/*Top Row Container*/}
        <div class="flex flex-col lg:flex-row md:space-y-0">

      <div class="flex flex-col p-4 lg:pr-4 lg:w-1/2">
      <div class="aspect-w-16 aspect-h-9">
        <YoutubeEmbed embedId="UQk5xthfSpM" />
      </div>
      </div>

      <div class="flex flex-col p-4 lg:pl-4 lg:w-1/2">
      <div class="aspect-w-16 aspect-h-9">
        <YoutubeEmbed embedId="eqgQ2jjRaGg" />
      </div>
      </div>

      </div>
      </div>
      <div class="mx-auto text-center">
        <button
                onClick={() => setShowFormModal(true)}
                class="inline-block px-6 py-1 border border-dustyRose text-center text-white bg-dustyRose hover:bg-ivory hover:border-dustyRose hover:text-dustyRose"
                >Contact</button>
        </div>
    </div>
  )
}
