import React, { useState} from "react";
import emailjs from '@emailjs/browser';
import DatePicker from "react-datepicker";
import "../../node_modules/react-datepicker/dist/react-datepicker.css"

export default function FormModal( 
  { showFormModal, setShowFormModal, solo, setSolo, trio, setTrio}) {
  const [date, setDate] = useState(new Date());
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [venue, setVenue] = useState("");
  const [info, setInfo] = useState("");

  const handleCloseModal = () => {
    setShowFormModal(false);
    setSubmitted(false);
    setDate(new Date());
    setName("");
    setEmail("");
    setVenue("");
    setInfo("");
    setTrio(false);
    setSolo(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_lbw15bn', 'template_pzxrgq8', 
    {name: name,
    email: email,
    solo: solo ? 'yes' : 'no',
    trio: trio ? 'yes' : 'no',
    date: date.toDateString(),
    venue: venue,
    info: info},
     'HDkBQ2K1c2OGRsc8c')
      .then((result) => {
          console.log(result.text);
          setSubmitted(true);
          setDate(new Date());
          setName("");
          setEmail("");
          setVenue("");
          setInfo("");
          setTrio(false);
          setSolo(false);
      }, (error) => {
          console.log(error.text);
      });
  };

  if (submitted) {
    return (
      <> {showFormModal ? (
        <>
        <div
          className="pt-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="h-[37rem] w-[22.5rem] border-0 shadow-lg relative flex flex-col outline-none focus:outline-none bg-ivory">
              {/*header*/}
              <div className="absolute left-1 top-0">
                <button
                  className="text-darkGray font-bold uppercase text-xl"
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  &times;
                </button>
              </div>
              <div className="pt-60 mx-auto flex items-start justify-between">
                <h3 className="text-center text-2xl font-bold text-darkGray">
                  Thanks for reaching out!
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-3">
                <p className="text-center">We will be in touch shortly.</p>

              </div>
              <button
            onClick={() => handleCloseModal()}
            class="p-1 block mx-auto border bg-white md:w-24 hover:scale-95"
          >
            Close
          </button>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>

      ) : null};
      </>
    )
  } 

else {

  return (
    <>
      {showFormModal ? (
        <div>
          <div
            className="pt-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="p-4 border-0 shadow-lg relative flex flex-col w-full bg-ivory outline-none focus:outline-none">
                {/*header*/}
                <div className="absolute left-1 top-0">
                  <button
                    className="text-darkGray font-bold uppercase text-xl"
                    type="button"
                    onClick={() => handleCloseModal()}
                  >
                    &times;
                  </button>
                </div>
                <div className="pt-3 mx-auto flex items-start justify-between">
                  <h3 className="text-center text-2xl font-bold text-darkGray">
                    Contact Us
                  </h3>
                </div>
                <div className="pt-1 pb-3 mx-auto flex items-start justify-between">
                  <p className="text-center text-xs text-darkGray">
                    We are currently booking weddings in Texas. <br/>Submit this form 
                    and we will be in touch soon!
                  </p>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                <form>
          <label class="block p-1">
            <span>Name</span>
            <input id="name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
            class="border border-black form-input mt-1 block w-full" placeholder="Name"/>
          </label>

          <label class="block p-1">
            <span >Email</span>
            <input id="email" value={email}
            onChange={(e) => setEmail(e.target.value)}
            class="border border-black form-input mt-1 block w-full" placeholder="Email"/>
          </label>

          <div class="block p-2">
            <span>Which of our packages are you interested in?</span>
            <div class="mt-2">
              <div>
                <label class="flex">
                  <input name="package" value={solo} checked={solo}
                    onClick={(e) => {
                      setSolo(e.target.checked)
                    }}type="radio" class="form-checkbox "/>
                  <span for="solo" class="ml-2">Solo Package</span>
                </label>
              </div> 
              <div>
                <label class="flex">
                  <input value={trio} checked={trio}
                    onClick={(e) => setTrio(e.target.checked)}name="package" type="radio" class="form-checkbox"/>
                  <span for="trio" class="ml-2">Trio Package</span>
                </label>
              </div>
            </div>
          </div>

          <label class="block p-2">
            <span>Date </span>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
            />
          </label>

          <label class="block p-1">
            <span>Venue</span>
            <input                     value={venue}
                    onChange={(e) => setVenue(e.target.value)} id="venue" class="border border-black form-input mt-1 block w-full" placeholder="Venue"/>
          </label>



          <label class="block">
            <span>Additional Info</span>
            <textarea                     value={info}
                    onChange={(e) => setInfo(e.target.value)}id="info" class="form-textarea mt-1 block w-full" rows="2" ></textarea>
          </label>

          <div class="pt-3">
            <button
            onClick={sendEmail}
            class="p-1 block mx-auto border bg-white md:w-24 hover:scale-95"
          >
            Submit
          </button>
          </div>
        </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}
}

