import Bio from './components/Bio';
import Features from './components/Features';
import Footer from './components/Footer';
import Videos from './components/Videos';
import Hero from './components/Hero';
import NavBar from './components/NavBar';
import Testimonials from './components/Testimonials';
import FormModal from './components/FormModal';
import SongbookModal from './components/SongbookModal';
import Packages from './components/Packages';
import { useState } from 'react';

function App() {
  const [showSongBookModal, setShowSongBookModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [solo, setSolo] = useState(false);
  const [trio, setTrio] = useState(false);

  return (
    <div className=" bg-ivory">
      <NavBar setShowFormModal={setShowFormModal} />
      <Hero/>
      <Videos setShowFormModal={setShowFormModal}/>
      <Features setShowSongBookModal={setShowSongBookModal}/>
      <Bio/>
      <Packages setShowFormModal={setShowFormModal} 
      setSolo={setSolo} setTrio={setTrio} 
      setShowSongBookModal={setShowSongBookModal}/>
      <Testimonials setShowFormModal={setShowFormModal}/>
      <Footer/>
      <SongbookModal setShowSongBookModal={setShowSongBookModal} showSongBookModal={showSongBookModal}/>
      <FormModal showFormModal={showFormModal} setShowFormModal={setShowFormModal}
      solo={solo} setSolo={setSolo} trio={trio} setTrio={setTrio} />
    </div>
  );
}

export default App;
