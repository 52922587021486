import React from 'react'

export default function Packages({ setShowFormModal, setSolo, setTrio, setShowSongBookModal}) {

  const handleContactSoloPackage = () => {
    setSolo(true);
    setShowFormModal(true);
  }

  const handleContactTrioPackage = () => {
    setTrio(true);
    setShowFormModal(true);
  }
  return (
    <div  class="pb-20 pt-12 md:pt-8">
      <h1 class="font-bold leading-normal text-center text-2xl lg:text-3xl md:pt-4 lg:pb-8 pb-1 text-darkGray">Packages</h1>
                {/* Global Container */}
                <div class="flex items-center justify-center">
                {/* Inner Container */}
                <div
        class="flex flex-col my-6 space-y-6 lg:space-y-0 lg:space-x-10 lg:flex-row lg:my-0">
                {/* Col 1 */}
                <div class="bg-white text-white max-w-[24rem]">
                {/* Upper Container */}
                <div class="p-6 mx-3 mt-3 bg-dustyRose">

                <div class="font-serif text-2xl text-center uppercase pb-6">Solo</div>

                   {/* List Container */}
                <div class="flex flex-col space-y-2">
                {/* Description */}
                <div class="flex justify-center">
                <span class="text-center">Nathan will sing and play guitar at your ceremony.</span>
              </div>
              <br/> 
              <br/> 

                {/* List Item 1 */}
                <div class="flex justify-center">
                <span class="text-sm ml-1">Included:</span>
              </div>


                {/* List Item 2 */}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Five ceremony songs picked from our 
                <button class="text-decoration-line: underline hover:text-ivory"
             onClick={() => setShowSongBookModal(true)}
             >songbook </button>&nbsp; and one 
                custom song.</span>
              </div>

                {/* List Item 3 */}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Full sound system for ceremony music, officiant, and any other participants.</span>
              </div>

                {/* List Item 4*/}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Production call with Nathan Bonnes to go over song
                selection and planning.</span>
              </div>
            </div>
          </div>
                {/* Border */}
                <div class="border-t border-white"></div>

                {/* Lower Container */}
                <div class="p-3 mx-3 mb-3 bg-dustyRose">

            <h3 class="text-xl text-center">$700</h3>
            <div class="flex justify-center">
              <button
                onClick={() => handleContactSoloPackage()}
                class="inline-block px-6 py-1 mt-4 text-center border border-ivory duration-200 hover:bg-ivory hover:border-ivory hover:text-dustyRose"
                >Contact</button>
            </div>
          </div>
        </div>
                {/* Col 2 */}
                <div class="bg-white text-white max-w-[24rem]">
                {/* Upper Container */}
                <div class="p-6 mx-3 mt-3 bg-dustyRose">

                <div class="font-serif text-2xl text-center uppercase pb-6">Trio</div>

                   {/* List Container */}
                <div class="flex flex-col space-y-2">
                {/* Description */}
                <div class="flex justify-center">
                
                <span class="text-center">Nathan will sing and play guitar at your ceremony, accompanied by 
                a cellist and a female vocalist.</span>
              </div>
              <br/> 
                {/* List Item 1 */}
                <div class="flex justify-center">
              
                <span class="text-sm ml-1">Included:</span>
              </div>


                {/* List Item 2 */}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Five ceremony songs picked from our              
                <button class="text-decoration-line: underline hover:text-ivory"
             onClick={() => setShowSongBookModal(true)}
             >songbook</button>&nbsp; and two 
                custom songs.</span>
              </div>

                {/* List Item 3 */}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Full sound system for ceremony music, officiant, and any other participants.</span>
              </div>

                {/* List Item 4*/}
                <div class="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
                <span class="text-sm ml-1 text-center">Production call with Nathan Bonnes to go over song
                selection and planning.</span>
              </div>
            </div>

          </div>

                {/* Border */}
                <div class="border-t border-white"></div>

                {/* Lower Container */}
                <div class="p-3 mx-3 mb-3 bg-dustyRose">

            <h3 class="text-xl text-center">$1200</h3>
            <div class="flex justify-center">
            <button
                onClick={() => handleContactTrioPackage()}
                class="inline-block px-6 py-1 mt-4 text-center border border-ivory duration-200 hover:bg-ivory hover:border-ivory hover:text-dustyRose"
                >Contact</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
