import React from 'react'
import nathanHeadshot from '../images/nathanHeadshot.jpeg'

export default function Bio() {
  return (
    <div>
        <section id="bio" class="px-4 py-2 md:py-4 lg:py-6">
            <div class="container mx-auto px-5 md:pb-12 pb-8 border border-solid border-dustyRose">
            <h3 class="mb-4 md:mb-8  pt-4 md:pt-8 text-center text-2xl 
            font-bold md:text-3xl text-darkGray">
                Meet Our Producer - Nathan Bonnes</h3>
            <div class="flex md:flex-row flex-col pt:6 max-w-4xl mx-auto">
            <div class="md:w-1/2 md:pt-5 lg:pt-3">
            <img class="pb-3 md:pt-[.7rem] block mx-auto lg:h-[15.5rem] lg:w-[14.5rem] md:h-[14.5rem] md:w-[13.5rem] h-[8rem] w-[7.2rem]" src={nathanHeadshot}></img>
            </div>
            <div class="md:w-1/2">
            <p class="block mx-auto md:text-left text-center text-sm md:text-base lg:text-lg">
            Nathan brings over 10 years of experience performing at 
            and producing large scale weddings, concerts, and TV broadcasts. 
            As a singer-songwriter, his songs have been streamed millions of times and 
            regularly charted 
            in the Top 50 on the Texas Country radio charts. As the
             producer of the Ode Wedding Music group, he brings his 
             background in storytelling to any
              wedding that he is a part of. He is an asset to wedding planners
               and couples that want to create beautiful and memorable moments in
               their wedding ceremony. </p>
            </div>
            </div>
            </div>
        </section>
    </div>
  )
}
