import cello from '../images/cello.svg'
import mic from '../images/mic.svg'
import soundSystem from '../images/soundsystem.svg'
import couple from '../images/couple.svg'
import React from 'react'

export default function Features( { setShowSongBookModal }) {

  return (
    <div>
        <section id="features" class="pt-10">
      <h1 class="text-darkGray max-w-3xl mx-auto mb-6 text-center 
      text-2xl font-bold leading-normal mt-2 md:text-3xl">
      Musical Elements of a Perfect Wedding Ceremony
    </h1>
      {/*Features Container*/}
      <div class="container mx-auto px-6 pb-24">
         {/*First Row*/}
        <div
          class="flex flex-col space-y-16 text-center md:flex-row md:space-y-0"
          
        >
          {/*First Item*/}
          <div class=" flex flex-col items-center space-y-2 md:w-1/2">
            <div class="flex items-center justify-center h-24 mb-4">
              <img src={mic} alt="" />
            </div>
            <h3 class="text-darkGray text-xl font-bold">Perfect Song Selection</h3>
            <p class="max-w-[28rem] md:max-w-[18rem] lg:max-w-[24rem] xl:max-w-[28rem]">
             Perfectly chosen and performed music will take your ceremony from
             cookie cutter to unforgettable. Check out our&nbsp; 
             <button class="font-bold text-decoration-line: underline hover:text-dustyRose"
             onClick={() => setShowSongBookModal(true)}
             >songbook</button>
             &nbsp; to get some song ideas, but remember we can also learn custom songs for your wedding!
             </p>



          </div>
          {/*Second Item*/}
          <div class=" flex flex-col items-center space-y-2 md:w-1/2">
            <div class="flex items-center justify-center h-24 mb-4">
              <img src={cello} alt="" />
            </div>
            <h3 class="text-darkGray text-xl font-bold">Brilliant Musicians</h3>
            <p class="max-w-[28rem] md:max-w-[18rem] lg:max-w-[24rem] xl:max-w-[28rem]">
              Ode Wedding Music is a team of some of the best vocalists and musicians in Texas. Your guests 
              will be raving about the concert-level performance they enjoyed while attending
              your ceremony. 
            </p>
          </div>
        </div>

        {/*Second Row*/}
        <div
          class="flex flex-col space-y-16 mt-16 text-center md:flex-row md:space-y-0"
        >
          {/*Third Item*/}
          <div class=" flex flex-col items-center space-y-2 md:w-1/2">
            <div class="flex items-center justify-center h-24 mb-4">
              <img src={soundSystem}alt="" />
            </div>
            <h3 class="text-darkGray text-xl font-bold">Crystal Clear Audio</h3>
            <p class="max-w-[28rem] md:max-w-[18rem] lg:max-w-[24rem] xl:max-w-[28rem]">
             Ever been to a wedding where you couldn't hear the officiant or the vows? 
             It's no fun. Our top of the line sound systems will
             ensure your guests can enjoy every part of your ceremony without straining
             to hear.
            </p>
          </div>
          {/*Fourth Item*/}
          <div class=" flex flex-col items-center space-y-2 md:w-1/2">
            <div class="flex items-center justify-center h-24 mb-4">
              <img src={couple} alt="" />
            </div>
            <h3 class="text-darkGray text-xl font-bold">A Producer to Manage All the Details</h3>
            <p class="max-w-[28rem] md:max-w-[18rem] lg:max-w-[24rem] xl:max-w-[28rem]">
              Our Producer Nathan Bonnes is an expert in working with couples to create the wedding ceremony 
              of their dreams.
              He will make sure that your taste and style is 
              reflected in every moment of your ceremony.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
