import React from 'react'
import annikajaydon from '../images/annikajaydon.jpeg'
import dennisCarolyn from '../images/dennisCarolyn.jpeg'
import laceyCody from '../images/laceyCody.jpeg'
import heidiIsaac from '../images/heidiIsaac.jpeg'
import quotes from '../images/bg-quotes.png'

export default function Testimonials({ setShowFormModal }) {
  return (
    <div>
    <section id="testimonials">
    <h1 class="font-bold leading-normal text-center text-2xl pt-2 lg:text-3xl md:pt-4 pb-1 text-darkGray">Testimonials</h1>
      <div class="container mx-auto px-6 md:p-12 md:pt-8 pb-6 md:pb-8">
        <div
          class="relative flex flex-col w-full space-y-6 lg:flex-row lg:space-y-0 lg:space-x-12"
        >
          <img
            src={quotes}
            alt=""
            class="absolute left-1 -top-2 w-6 lg:-top-12 lg:w-10 filter: grayscale"
          />

          <div
            class="flex flex-col p-10 space-y-6 bg-dustyRose  lg:w-1/3"
          >
            <p class="text-darkGray text-sm leading-5 md:text-base lg:text-lg">
            Nathan did an amazing job with my wedding! He listened to exactly what we wanted and 
            created some of our favorite moments. 
            Nathan was knowledgeable about the scheduling and timetables for weddings, 
            which was helpful when planning with him.
            </p>

            <div class="flex space-x-4">
              <img
                src={annikajaydon}
                alt=""
                class="w-10 h-10"
              />
              <div>
                <h5 class="text-darkGrey text-sm font-semibold">Annika &  <br/>Jaydon Maehs</h5>
                <p class="text-darkGrey text-xs font-extralight">Married 9/17/2022</p>
              </div>
            </div>
          </div>


          <div
            class="flex flex-col p-10 space-y-6 bg-dustyRose  lg:w-1/3"
          >
            <p class="text-darkGray text-sm leading-5 md:text-base lg:text-lg">
            Nathan played music for our wedding ceremony and I'm so glad we hired him.
            He learned my favorite song for my walk down the aisle, It's Your Love 
            by Tim McGraw. He sounded just like the record! All of our guests that the ceremony music was 
            perfect.
            </p>
            <div class="flex space-x-4">
              <img
                src={heidiIsaac}
                alt=""
                class="w-10 h-10"
              />
              <div>
                <h5 class="text-darkGray text-sm font-semibold">Heidi & <br/>Isaac Partain</h5>
                <p class="text-darkGray text-xs font-extralight">Married 4/24/2021</p>
              </div>
            </div>
          </div>


          <div
            class="flex flex-col p-10 space-y-6 bg-dustyRose  lg:w-1/3"
          >
            <p class="text-darkGray text-sm leading-5 md:text-base lg:text-lg">
            Nathan performed at our wedding and it was a wonderful experience. 
            He listened to what we wanted, was very easy to work with and made 
            our first dance extra special! We are so happy that Nathan and 
            his music were a part of our wedding day.
            </p>
            <div class="flex space-x-4">
              <img
                src={laceyCody}
                alt=""
                class="w-10 h-10"
              />
              <div>
                <h5 class="text-darkGray text-sm font-semibold">Lacey &  <br/>Cody Compton</h5>
                <p class="text-darkGray text-xs font-extralight">Married 3/31/2018</p>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4 mx-auto text-center">
        <button
                onClick={() => setShowFormModal(true)}
                class="inline-block px-6 py-1 mt-2 border border-dustyRose text-center text-white bg-dustyRose hover:bg-ivory hover:border-dustyRose hover:text-dustyRose"
                >Contact</button>
        </div>
      </div>
    </section>
    </div>
  )
}
